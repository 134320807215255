import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyADO9o4G4qwVbbeSvPbD5HqArBANxg7TyQ",
  authDomain: "pride-wall.firebaseapp.com",
  projectId: "pride-wall",
  storageBucket: "pride-wall.appspot.com",
  messagingSenderId: "166398602798",
  appId: "1:166398602798:web:b0fbe5a151983156cb39bf",
  measurementId: "G-XX2PLBKF52"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

