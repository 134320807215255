import React, { useEffect, useState } from "react";
import { User } from "../types/user";
import gift1 from "../assets/icons/present.svg";
import gift2 from "../assets/icons/heart.svg";
import gift3 from "../assets/icons/follow.svg";
import closeIcon from "../assets/icons/close.svg";
import closeWhiteIcon from "../assets/icons/close-white.svg";
import ClipLoader from "react-spinners/ClipLoader";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { API_URL } from "../config/apiConfig";

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  handleFetchUsers: () => void;
  user: User;
}

interface Gift {
  name: string;
  image: string;
  point: number;
  hidden?: boolean;
  description: string;
}

const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  onClose,
  user,
  handleFetchUsers,
}) => {
  const [gifts, setGifts] = useState<Gift[]>([]);
  const [selectedGift, setSelectedGift] = useState<Gift | null>();
  const [query, setQuery] = useState<string>("");
  const [selectedAmount, setSelectedAmount] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userAvatar, setUserAvatar] = useState<string>("");

  useEffect(() => {
    const gifts: Gift[] = [
      {
        name: "tapowanie",
        image: gift2,
        point: 1,
        description: "Przyznawane przez organizatorów live",
      },
      {
        name: "obserwacja",
        image: gift3,
        point: 5,
        description: "Zaobserwuj profil i otrzymaj dodatkowe 5 punktów",
      },
      {
        name: "prezent",
        image: gift1,
        point: 1,
        description: "Wartość prezentu jest mnożona przez ilość punktów",
      },
    ];

    setGifts(gifts);

    setUserAvatar(`${API_URL}${user.avatar}`);
  }, []);

  const handleAddPoints = async (): Promise<void> => {
    if (user.id && selectedGift && selectedAmount) {
      setIsLoading(true);

      const userRef = doc(db, "users", user.id);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const currentPoints = userData.points || 0;

        const newPoints =
          currentPoints + selectedGift.point * parseInt(selectedAmount);

        await updateDoc(userRef, { points: newPoints });
        handleFetchUsers();

        setSelectedAmount("");
        setQuery("");
        setSelectedGift(null);
        setIsLoading(false);
        onClose();
      }
    }
  };

  const handleSelectGift = (selectedGift: Gift): void => {
    setSelectedAmount("");
    setSelectedGift(selectedGift);
  };

  const prepareCorrectPointName = (number: number): string => {
    const lastDigit = number % 10;
    const secondLastDigit = Math.floor((number % 100) / 10);

    if (secondLastDigit === 1) {
      return `${number} punktów`;
    } else if (lastDigit === 1) {
      return `${number} punkt`;
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      return `${number} punkty`;
    } else {
      return `${number} punktów`;
    }
  };

  const renderGiftInfo = () => {
    if (gifts && gifts.length) {
      return gifts.map((gift: Gift) => {
        return (
          <div className="mb-2 text-sm">
            <div>
              <b>{gift.name}</b>
            </div>
            <div>{gift.description}</div>
          </div>
        );
      });
    }
    return null;
  };

  const renderGifts = () => {
    if (gifts && gifts.length) {
      return gifts
        .filter((gift: Gift) => {
          if (!query) {
            return true;
          }

          if (query && gift.name.includes(query)) {
            return true;
          } else {
            return false;
          }
        })
        .map((gift: Gift, index: number) => {
          return (
            <div
              key={`single-gift-${index}`}
              className="cursor-pointer "
              onClick={() => handleSelectGift(gift)}
            >
              <span className="block text-sm font-medium leading-6 text-black text-center">
                {gift.name}
              </span>
              <div className="hover:border-yellow-400  border-4 border-transparent rounded">
                <div className="bg-bgGift flex justify-center rounded-t h-[70px]">
                  <img
                    width={40}
                    height={40}
                    src={gift.image}
                    className="rounded-t"
                  />
                </div>
                <div className="bg-red-700 rounded-b p-1 text-xs text-white text-center">
                  {prepareCorrectPointName(gift.point)}
                </div>
              </div>
            </div>
          );
        });
    }

    return (
      <div className="font-bold text-l mt-6">Brak prezenntów do wyboru</div>
    );
  };

  return (
    <div
      className={`fixed top-0 right-0 h-full w-[400px] bg-gray-200 shadow-lg transition-transform duration-300 ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="p-4 flex justify-between items-center">
        <div className="flex flex-col">
          <span className="font-bold text-xl">Dodaj punkty</span>
          <span className="font-bold text-base text-red-500">@{user.nick}</span>
        </div>
        <button
          onClick={onClose}
          className="bg-red-600 text-white px-4 py-2 rounded"
        >
          Zamknij
        </button>
      </div>
      <div className="px-4">
        <div className="flex justify-center rounded bg-zinc-900 p-4">
          <img
            width={80}
            height={80}
            className="rounded-full"
            src={userAvatar}
          />
        </div>
        <hr className="mt-7 border-t border-gray-300 my-4" />
        <div className="font-bold text-l mt-3">Wybierz mnożnik</div>
        <div className="mt-2">{renderGiftInfo()}</div>
        <div className="grid grid-cols-3 gap-4 mt-4 max-h-[400px] overflow-y-auto">
          {renderGifts()}
        </div>
        {/* <hr className="mt-7 border-t border-gray-300 my-4" /> */}
        {/* <div className="mt-3">
          <label
            htmlFor="search"
            className="block text-sm font-medium leading-6 text-black"
          >
            Szukaj prezentów
          </label>
          <div className="flex flex-row">
            <input
              type="text"
              autoComplete="off"
              placeholder="Wpisz nazwę prezentu"
              name="search"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className="ps-6 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
            <img
              src={closeIcon}
              onClick={() => setQuery("")}
              width={24}
              height={24}
              className="ms-3 cursor-pointer"
            />
          </div>
        </div> */}
        <hr className="mt-7 border-t border-gray-300 my-7" />
        {selectedGift && (
          <div className="bg-zinc-900 p-5 rounded relative">
            <img
              src={closeWhiteIcon}
              onClick={() => setSelectedGift(null)}
              width={24}
              height={24}
              className="absolute top-4 right-4 cursor-pointer"
            />
            <label
              htmlFor="search"
              className="block text-sm font-medium leading-6 text-white"
            >
              Wybrano prezent
            </label>
            <div className="flex justify-center mt-3 items-center">
              <div className="bg-stone-700 p-4 rounded-full">
                <img width={40} height={40} src={selectedGift.image} />
              </div>

              <div className="bg-red-700 rounded p-2 text-xs text-white text-center ms-5 w-[100px]">
                {prepareCorrectPointName(selectedGift.point)}
              </div>
            </div>
            <div className="flex mt-4">
              <input
                type="number"
                autoComplete="off"
                placeholder="Ilość"
                name="count"
                onChange={(e) => setSelectedAmount(e.target.value)}
                value={selectedAmount}
                className="ps-6 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              />
              <button
                onClick={handleAddPoints}
                className="w-[150px] ms-3 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {!isLoading ? (
                  "Dodaj"
                ) : (
                  <ClipLoader color={"black"} loading={true} size={24} />
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Drawer;
