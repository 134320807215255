import React, { useEffect, useState } from "react";
import AddUser from "./AddUser";
import logo from "../assets/logo.png";
import crownIcon from "../assets/gold_crown.png";
import addIcon from "../assets/icons/plus.svg";
import { User } from "../types/user";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebaseConfig";
import {
  collection,
  doc,
  getDocs,
  updateDoc,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { API_URL } from "../config/apiConfig";

const Home: React.FC = () => {
  const location = useLocation();
  const [users, setUsers] = useState<User[]>([]);
  const [user] = useAuthState(auth);
  const [userCount, setUserCount] = useState<number>(0);
  const [choosedUser, setChoosedUser] = useState<string>("");
  const SYNC_AVATARS = false;

  useEffect(() => {
    if (SYNC_AVATARS) {
      const updateAvatars = async () => {
        const usersRef = collection(db, "users");
        const snapshot = await getDocs(usersRef);
        const users: User[] = [];

        snapshot.forEach((doc) => {
          const userData = doc.data();
          users.push({
            id: doc.id,
            avatar: userData.avatar,
            points: userData.points,
            nick: userData.nick,
          });
        });

        const response = await fetch(`${API_URL}/api/update-user-avatars`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ users }),
        });

        const data = await response.json();
        if (data && data.users && data.users.length) {
          for (const updatedUser of data.users) {
            const userRef = doc(db, "users", updatedUser.id);
            await updateDoc(userRef, {
              avatar: updatedUser.avatar,
            });
          }
        }
      };
      updateAvatars();
    }
  }, []);

  useEffect(() => {
    handleFetchUsers();
  }, []);

  const getQueryParam = (param: any) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  const devMode = getQueryParam("devMode") === "true";

  const handleFetchUsers = async () => {
    const usersRef = collection(db, "users");
    const usersQuery = query(
      usersRef,
      where("points", ">", 0),
      orderBy("points", "desc")
    );
    const snapshot = await getDocs(usersQuery);

    const userList: User[] = snapshot.docs.map((doc) => ({
      id: doc.id,
      nick: doc.data().nick as string,
      avatar: doc.data().avatar as string,
      points: doc.data().points as number,
    }));

    if (userList && userList.length) {
      setUsers(userList);
      setUserCount(userList.length);
    }
  };

  const handleClickProfile = (nick: string): void => {
    window.open(
      `https://www.tiktok.com/@${nick}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const renderUsers = () => {
    if (users && users.length) {
      const tmpUsers = groupUsers(users);
      let globalIndex = 0;

      return tmpUsers.map((groupUsers: User[], sectionIndex: number) => {
        return (
          <div
            className={`section-${sectionIndex + 1} flex`}
            key={`single-section-${sectionIndex}`}
          >
            {groupUsers.map((user: User, userIndex: number) => {
              const avatarUrl = `${API_URL}${user.avatar}`;
              globalIndex++;
              return (
                <div
                  key={`single-section-user-${userIndex}`}
                  className="p-3 relative mb-5 cursor-pointer flex justify-center flex-col items-center"
                >
                  {globalIndex === 1 && (
                    <img
                      className="absolute top-[-40px]"
                      src={crownIcon}
                      width={70}
                      height={70}
                    />
                  )}
                  <img
                    width={100}
                    height={100}
                    className="rounded-full"
                    src={avatarUrl}
                  />

                  <div
                    className="bg-slate-950 text-white text-xs rounded mt-1 text-center py-1 px-3 font-bold w-[140px]"
                    onClick={() => handleClickProfile(user.nick)}
                  >
                    <div>@{user.nick}</div>
                  </div>
                  <div className="flex justify-center items-center">
                    <div
                      className="bg-red-600 text-white text-xs rounded-full mt-1 text-center py-1 px-3 font-bold"
                      onClick={() => handleClickProfile(user.nick)}
                    >
                      <div> {user.points} pkt.</div>
                    </div>
                    <div
                      className="bg-green-500 rounded-full bottom-0 w-[24px] h-[24px] mt-1 ms-1"
                      onClick={() => setChoosedUser(user.nick)}
                    >
                      <img src={addIcon} width={24} />
                    </div>
                  </div>
                  {globalIndex === 1 && (
                    <div className="absolute w-[22px] h-[22px] flex top-[-4px] left-1/2 transform -translate-x-1/2 bg-black font-bold rounded-full text-white text-center text-sm justify-center items-center">
                      <span>{globalIndex}</span>
                    </div>
                  )}
                  {globalIndex > 1 && (
                    <div className="absolute flex top-0 left-1/2 transform -translate-x-1/2 bg-black font-bold py-1 px-3 rounded-full text-white text-center text-sm justify-center items-center">
                      <span>{globalIndex}</span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      });
    }

    return (
      <div className="font-bold font-medium text-l">
        Bądź pierwszy na ścianie Pride Wall!
      </div>
    );
  };

  const groupUsers = (users: User[]) => {
    const result = [];
    let index = 0;

    if (users.length > 0) {
      result.push([users[index]]);
      index++;
    }

    if (index + 1 < users.length) {
      result.push(users.slice(index, index + 2));
      index += 2;
    }

    while (index < users.length) {
      result.push(users.slice(index, index + 3));
      index += 3;
    }

    return result;
  };

  return (
    <>
      <div className="flex justify-center logo-wrapper bg-black">
        <img src={logo} width={220} />
      </div>
      {devMode && <div className="fixed top-[80px]">{userCount}</div>}
      <div className="users-wrapper flex items-center flex-col">
        {renderUsers()}
      </div>
      <div className="fixed link-app bottom-0 justify-center items-center bottom-[155px] text-center">
        <span className="text-[40px] font-bold bg-white p-2 rounded">
          pridewall.pl
        </span>
      </div>
      {user && (
        <div className="container mx-auto">
          <div className="fixed bottom-0 bg-black inset-x-0 pt-6 pb-10 flex justify-center">
            <AddUser
              handleFetchUsers={handleFetchUsers}
              choosedUser={choosedUser}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
