import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import Drawer from "./Drawer";
import { AvatarResponse, User } from "../types/user";
import ClipLoader from "react-spinners/ClipLoader";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { toast } from "react-toastify";
import { API_URL } from "../config/apiConfig";

interface AddUserProps {
  handleFetchUsers: () => void;
  choosedUser?: string;
}

const AddUser: React.FC<AddUserProps> = ({ handleFetchUsers, choosedUser }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [nick, setNick] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(false);

  useEffect(() => {
    if (choosedUser) {
      setNick(choosedUser);
    }
  }, [choosedUser]);

  const fetchAvatar = async (username: string) => {
    const response = await fetch(`${API_URL}/api/tiktok/${username}`);
    return await response.json();
  };

  const handleFindUser = async (nick: string): Promise<void> => {
    if (nick) {
      setIsUserLoading(true);

      const usersRef = collection(db, "users");
      const q = query(usersRef, where("nick", "==", nick)); // Zmienna do wyszukiwania
      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        const foundUserDoc = snapshot.docs[0];
        const userData = foundUserDoc.data();

        if (foundUserDoc) {
          setSelectedUser({
            id: foundUserDoc.id,
            nick: userData.nick,
            avatar: userData.avatar,
            points: userData.points,
          });
        }
      } else {
        const avatar: AvatarResponse = await fetchAvatar(nick);

        if (!avatar.avatarUrl) {
          toast.error("Użytkownik nie istnieje", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setIsUserLoading(false);
          return;
        }
        const newUser: User = {
          nick: nick,
          avatar: avatar.avatarUrl,
          points: 0,
        };

        const docRef = await addDoc(usersRef, newUser);
        newUser.id = docRef.id;
        setSelectedUser(newUser);
      }

      setIsDrawerOpen(true);
      setIsUserLoading(false);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Zapobiega domyślnemu przeładowaniu formularza (jeśli jest w formie)
      handleFindUser(e.target.value);
    }
  };

  return (
    <div className="add-user-wrapper">
      <div className="sm:col-span-3 mt-3 add-user-form">
        <label
          htmlFor="nick"
          className="block text-sm font-medium leading-6 text-white"
        >
          Aktualizuj użytkownika
        </label>
        <div className="mt-2 flex justify-center relative">
          <input
            type="text"
            name="nick"
            autoComplete="off"
            value={nick}
            onKeyDown={handleKeyDown}
            onChange={(e) => setNick(e.target.value)}
            className="ps-6 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          />
          <span className="absolute left-2 top-2 text-gray-400">@</span>
          <button
            onClick={() => handleFindUser(nick)}
            className="w-[150px] ms-3 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {!isUserLoading ? (
              "Wyszukaj"
            ) : (
              <ClipLoader color={"black"} loading={true} size={24} />
            )}
          </button>
        </div>
      </div>
      {selectedUser && (
        <Drawer
          isOpen={isDrawerOpen}
          onClose={() => {
            setIsDrawerOpen(false);
            setSelectedUser(null);
          }}
          handleFetchUsers={handleFetchUsers}
          user={selectedUser}
        />
      )}
    </div>
  );
};

export default AddUser;
